<template>
  <div class="pricingWrapper">
  <b-spinner style="width: 50px; height: 50px" class="spinner"  v-if="showSpinner"/>
    <b-row>
      <!-- Create Ad -->
      <b-col md="12">
        <b-card>
          <b-row>
            <b-col md="12">
              <h3 class="card-title">Pricing</h3>
            </b-col>
          </b-row>
          <b-row>
              <validation-observer ref="saleByOwnerForm">
            <b-tabs content-class="mt-1">
                <b-tab title="Mental or Detox Facility"  @click="clickTab('mental_or_detox_facility')">
                  <b-alert variant="" show class="mb-0">
                    <b-row>
                      <b-col md="5" sm="6" class="mb-0">
                        <validation-provider
                          #default="{ errors }"
                          name="madical or detox facility price"
                          rules="required"
                        >
                          <b-form-group>
                            <div class="d-flex">
                              <div>
                                <b-form-input
                                  v-model="saleByOwner"
                                  @keypress="isCharAt($event)"
                                  :maxLength="20"
                                  autocomplete="off"
                                  required
                                />
                                <small class="text-danger" style="
                                    display: block;
                                    position: absolute;
                                    width: 100%;
                                  " v-if="priceError != ''">{{priceError}}</small>
                                <small v-else
                                  class="text-danger"
                                  style="
                                    display: block;
                                    position: absolute;
                                    width: 100%;
                                  "
                                  >{{ errors[0] }}</small
                                >
                              </div>
                              <b-button
                                variant="primary"
                                :disabled="showSpinner"
                                @click="submitData('mental_or_detox_facility', saleByOwner)"
                                >Save</b-button
                              >
                              <b-button
                                variant="outline-secondary"
                                class="mr-1 text-button"
                                :disabled="showSpinner"
                                @click="cancelTab()"
                                >Cancel</b-button
                              >
                            </div>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-alert>
                </b-tab>
                <b-tab title="Free or Public Shelter"  @click="clickTab('free_or_public_shelter')">
                  <b-alert variant="" show class="mb-0">
                    <b-row>
                      <b-col md="5" sm="6" class="mb-0">
                        <validation-provider
                          #default="{ errors }"
                          name="free or public shelter price"
                          rules="required"
                        >
                          <b-form-group>
                            <div class="d-flex">
                              <div>
                                <b-form-input
                                  v-model="saleByOwner"
                                  @keypress="isCharAt($event)"
                                  :maxLength="20"
                                  autocomplete="off"
                                  required
                                />
                               <small class="text-danger" style="
                                    display: block;
                                    position: absolute;
                                    width: 100%;
                                  " v-if="priceError != ''">{{priceError}}</small>
                                <small v-else
                                  class="text-danger"
                                  style="
                                    display: block;
                                    position: absolute;
                                    width: 100%;
                                  "
                                  >{{ errors[0] }}</small
                                >
                              </div>
                              <b-button
                                variant="primary"
                                :disabled="showSpinner"
                                @click="
                                  submitData(
                                    'free_or_public_shelter',
                                    saleByOwner
                                  )
                                "
                                >Save</b-button
                              >
                              <b-button
                                variant="outline-secondary"
                                class="mr-1 text-button"
                                :disabled="showSpinner"
                                 @click="cancelTab()"
                                >Cancel</b-button
                              >
                            </div>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-alert>
                </b-tab>
                <b-tab title="Half-Way or Shared House"  @click="clickTab('half_way_or_shared')">
                  <b-alert variant="" show class="mb-0">
                    <b-row>
                      <b-col md="5" sm="6" class="mb-0">
                        <validation-provider
                          #default="{ errors }"
                          name="half-way or shared house price"
                          rules="required"
                        >
                          <b-form-group>
                            <div class="d-flex">
                              <div>
                                <b-form-input v-model="saleByOwner" autocomplete="off" @keypress="isCharAt($event)" :maxLength="20" required />
                                <small class="text-danger" style="
                                    display: block;
                                    position: absolute;
                                    width: 100%;
                                  " v-if="priceError != ''">{{priceError}}</small>
                                <small v-else
                                  class="text-danger"
                                  style="
                                    display: block;
                                    position: absolute;
                                    width: 100%;
                                  "
                                  >{{ errors[0] }}</small
                                >
                              </div>
                              <b-button
                                variant="primary"
                                :disabled="showSpinner"
                                @click="
                                  submitData('half_way_or_shared', saleByOwner)
                                "
                                >Save</b-button
                              >
                              <b-button
                                variant="outline-secondary"
                                class="mr-1 text-button"
                                :disabled="showSpinner"
                                 @click="cancelTab()"
                                >Cancel</b-button
                              >
                            </div>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-alert>
                </b-tab>
            </b-tabs>
              </validation-observer>
          </b-row>
          <b-row v-if="activeTab=='mental_or_detox_facility'" class="mt-30">
            <b-col cols="12" v-if="saleByOwnerArray.length > 0">
              <b-table
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="saleByOwnerArray"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
               <template #cell(Number)="data">
                  <div style="display: flex" >
                      {{data.index+1}}
                  </div>
                </template>
               <template #cell(Price)="data">
                  <div style="display: flex" >
                      {{data.item.Price != 0 ? '$'+setCommaSeprateNumber(data.item.Price) : data.item.Price}}
                  </div>
                </template>
              </b-table>
            </b-col>
             <b-col
              v-if="saleByOwnerArray.length == 0 && !showSpinner"
              cols="12"
              style="width: 100%; text-align: center"
            >
              <p>No records found</p>
            </b-col>
             <b-col class="d-flex justify-content-between flex-wrap pt-0 paginationCol" v-if="saleByOwnerArray.length > 0">
              <!-- page length -->
               <div
                class="d-flex align-items-center"
                style="white-space: nowrap"
              >
                <span class="showText">Showing 1 to</span>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  inline
                  :options="pageOptions"
                  style="margin: 0px 10px"
                />
                <span class="entiresText">of {{ saleByOwnerArray.length }} </span> <span class="entiresText">entries</span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="saleByOwnerArray.length"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-col>
          </b-row>
           <b-row v-if="activeTab=='free_or_public_shelter'" class="mt-30">
            <b-col cols="12" v-if="assignYourContract.length > 0">
              <b-table
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="assignYourContract"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
               <template #cell(Number)="data">
                  <div style="display: flex" >
                      {{data.index+1}}
                  </div>
                </template>
               <template #cell(Price)="data">
                  <div style="display: flex" >
                      {{data.item.Price != 0 ? '$'+setCommaSeprateNumber(data.item.Price) : data.item.Price}}
                  </div>
                </template>
              </b-table>
            </b-col>
             <b-col
              v-if="assignYourContract.length == 0 && !showSpinner"
              cols="12"
              style="width: 100%; text-align: center"
            >
              <p>No records found</p>
            </b-col>
              <b-col class="d-flex justify-content-between flex-wrap pt-0 paginationCol" v-if="assignYourContract.length > 0">
              <!-- page length -->
               <div
                class="d-flex align-items-center"
                style="white-space: nowrap"
              >
                <span class="showText">Showing 1 to</span>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  inline
                  :options="pageOptions"
                  style="margin: 0px 10px"
                />
                <span class="entiresText">of {{ assignYourContract.length }} </span> <span class="entiresText">entries</span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="assignYourContract.length"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-col>
          </b-row>
           <b-row v-if="activeTab=='half_way_or_shared'" class="mt-30">
            <b-col cols="12" v-if="listYourMortgage.length > 0">
              <b-table
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="listYourMortgage"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
               <template #cell(Number)="data">
                  <div style="display: flex" >
                      {{data.index+1}}
                  </div>
                </template>
               <template #cell(Price)="data">
                  <div style="display: flex" >
                      {{data.item.Price != 0 ? '$'+setCommaSeprateNumber(data.item.Price) : data.item.Price}}
                  </div>
                </template>
              </b-table>
            </b-col>
             <b-col
              v-if="listYourMortgage.length == 0 && !showSpinner"
              cols="12"
              style="width: 100%; text-align: center"
            >
              <p>No records found</p>
            </b-col>
              <b-col class="d-flex justify-content-between flex-wrap pt-0 paginationCol" v-if="listYourMortgage.length > 0">
              <!-- page length -->
               <div
                class="d-flex align-items-center"
                style="white-space: nowrap"
              >
                <span class="showText">Showing 1 to</span>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  inline
                  :options="pageOptions"
                  style="margin: 0px 10px"
                />
                <span class="entiresText">of {{ listYourMortgage.length }} </span> <span class="entiresText">entries</span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="listYourMortgage.length"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BSpinner,
  BCard,
  BFormDatepicker,
  BFormFile,
  BButton,
  BTable,
  BPagination,
  BFormSelect,
  BBadge,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { dbCollections } from "@/utils/firebaseCollections";
import { getCollectionData } from "@/utils/FirebaseQueries/getQueries";
import * as insertFirebase from "@/utils/FirebaseQueries/insertQueries/insertQueries.js";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import { required } from "@validations";
import { mapGetters, mapActions } from "vuex";
import {getCommaSeperatedNumber } from "@/utils/commonMethods";
import axios from "axios";
import moment from "moment";
export default {
  name: "adverisment-management",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BSpinner,
    BCard,
    BFormDatepicker,
    BFormFile,
    BButton,
    BTable,
    BPagination,
    BFormSelect,
    BBadge,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      saleByOwner: "",
      showSpinner: false,
      activeTab:'mental_or_detox_facility',
      priceError:'',
      saleByOwnerLastValue:0,
      assignYourContractValue:0,
      listYourNoteValue:0,
      priceError:'',
      fields: [
        {
          key: "Number",
          label: "Number",
        },
        {
          key: "Price",
          label: "Price",
        },
        {
          key: "Date",
          label: "Date",
        },
      ],
      onFiltered:[],
      sortDesc: true,
      sortDirection: "desc",
      filter: null,
      filterOn: [],
      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      saleByOwnerArray:[],
      assignYourContract:[],
      listYourMortgage:[],
      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: true,
      sortDirection: "desc",
      filter: null,
      filterOn: [],

    };
  },
  created(){
    var self = this;
    self.saleByOwnerArray = [];
    self.assignYourContract=[];
    self.listYourMortgage=[];
    self.showSpinner = true;
    self.saleByOwner = '';
    self.getSubPriceCollection().then(() => {

      self.subScriptionArray = [];
      getCollectionData(`${dbCollections.SUBSCRIPTION_PRICE}`,0,0,{field:"createdAt",value:-1}).then((res) => {
        if(!res.empty){
          res.forEach(doc => {
            if(self.saleByOwnerLastValue == 0 && doc.data().type == 'mental_or_detox_facility'){
                self.saleByOwnerLastValue = doc.data().price;
                // self.saleByOwner = doc.data().price;
            }
            if(self.assignYourContractValue == 0 && doc.data().type == 'free_or_public_shelter'){
                self.assignYourContractValue = doc.data().price;
            }
            if(self.listYourNoteValue == 0 && (doc.data().type == 'half_way_or_shared')){
              self.listYourNoteValue = doc.data().price;
            }
            if(doc.data().type == 'mental_or_detox_facility'){
              self.saleByOwnerArray.push({Price:doc.data().price,Date:moment(doc.data().createdAt.seconds * 1000).format('MM/DD/YYYY'),key:doc.data().type,dateInSec:doc.data().createdAt.seconds});
              self.saleByOwnerArray.sort((book1, book2) => {
                return self.compareObjects(book1, book2, "dateInSec");
              });
            }
            if(doc.data().type == 'free_or_public_shelter'){
              self.assignYourContract.push({Price:doc.data().price,Date:moment(doc.data().createdAt.seconds * 1000).format('MM/DD/YYYY'),key:doc.data().type,dateInSec:doc.data().createdAt.seconds});
              self.assignYourContract.sort((book1, book2) => {
                return self.compareObjects(book1, book2, "dateInSec");
              });
            }
            if(doc.data().type == 'half_way_or_shared'){
              self.listYourMortgage.push({Price:doc.data().price,Date:moment(doc.data().createdAt.seconds * 1000).format('MM/DD/YYYY'),key:doc.data().type,dateInSec:doc.data().createdAt.seconds});
              self.listYourMortgage.sort((book1, book2) => {
                return self.compareObjects(book1, book2, "dateInSec");
              });
            }
          })
          self.showSpinner = false;
          // console.log('saleByOwner', self.saleByOwner,'assign your contract',self.assignYourContract,'list your note',self.listYourMortgage);
  
        }else{
          self.showSpinner = false;
        }
      })
    });
  },
  methods: {
    ...mapActions({getSubPriceCollection:"admin/getSubscriptionPrice"}),
    submitData(key, value) {
      // debugger; // eslint-disable-line
      var self = this;
      self.priceError = '';
      self.showSpinner = true;
        self.$refs.saleByOwnerForm.validate().then((valid) => {
          if (valid) {
            if(key == "mental_or_detox_facility" &&  Number(value) == self.saleByOwnerLastValue){
              self.priceError = 'Price value is same as previous';            
            }
            if(key == "free_or_public_shelter" &&  Number(value) == self.assignYourContractValue){
             self.priceError = 'Price value is same as previous';       
            }
            if(key == "half_way_or_shared" &&  Number(value) == self.listYourNoteValue){
             self.priceError = 'Price value is same as previous';       
            }
            if(self.priceError == ''){

              var index = self.subscriptionPriceArray.prices.findIndex((item) => {
                return item.listingType == key
              })
              // axios.post(process.env.VUE_APP_APIURL+'/api/v1/create-price',{
                axios.post(process.env.VUE_APP_APIURL+'/api/v1/create-price',{
                  "currency": "usd",
                  "amount": Number(value)*100,
                  "product": self.subscriptionPriceArray.prices[index].productId,
                  "interval": "month",
                  "interval_count": 1
              }).then(apiRes => {
                  if(apiRes.data.status){
                    if(apiRes.data.priceObj){
                      self.subscriptionPriceArray.prices[index].priceId = apiRes.data.priceObj.id,
                      self.subscriptionPriceArray.prices[index].amount = (apiRes.data.priceObj.unit_amount)/100
                    }else{
                      self.subscriptionPriceArray.prices[index].priceId = '',
                      self.subscriptionPriceArray.prices[index].amount = 0
                    }
                      let insertObject = {
                        type: key,
                        price: Number(value),
                        createdAt: new Date(),
                        priceObj:self.subscriptionPriceArray.prices[index]
                      };
                   
                    insertFirebase
                      .addDataToRootCollection(
                        dbCollections.SUBSCRIPTION_PRICE,
                        insertObject,
                        (res) => {
                          if (res) {
                            if(key == "mental_or_detox_facility"){
                              self.saleByOwnerLastValue = Number(value);
                              self.saleByOwner =  Number(value);
                              self.saleByOwnerArray.push({Price:Number(value),Date:moment(new Date()).format('MM/DD/YYYY'),key:key,dateInSec:new Date().getSeconds()});
                              self.sortingArray(self.saleByOwnerArray);
                            }
                            if(key == "free_or_public_shelter"){
                              self.assignYourContractValue = Number(value);
                              self.saleByOwner =  Number(value);
                              self.assignYourContract.push({Price:Number(value),Date:moment(new Date()).format('MM/DD/YYYY'),key:key,dateInSec:new Date().getSeconds()});
                              self.sortingArray(self.assignYourContract);
                            
                            }
                            if(key == "half_way_or_shared"){
                              self.listYourNoteValue = Number(value);
                              self.saleByOwner =  Number(value);
                              self.listYourMortgage.push({Price:Number(value),Date:moment(new Date()).format('MM/DD/YYYY'),key:key,dateInSec:new Date().getSeconds()});
                              self.sortingArray(self.listYourMortgage);
                            }
                            self.$refs.saleByOwnerForm.reset();
                            self.priceError = '';
                            updateFirebase.updateRootCollectionDataByDocId(dbCollections.SETTINGS,'subscriptionPrice',self.subscriptionPriceArray,(res)=>{
                              self.$toast.success(
                                "Subscription price added successfully"
                              );
                              self.showSpinner = false;
                            })
                          }
                        }
                      )
                  }else{
                    self.showSpinner = false;
                    self.$toast.error('Something went to wrong');
                  }
              }).catch(error => {
                self.showSpinner = false;
                console.log('ERROR in create price api call',error);
              })
             
            }else{
              self.showSpinner = false;
            }
          } else {
            console.log("not valid");
            self.showSpinner = false;
          }
        });
    },
    isCharAt(evt) {
      // debugger; // eslint-disable-line
      const char = String.fromCharCode(evt.keyCode);
      if (!/[0-9 ||.]/.test(char)) {
        evt.preventDefault();
        return false;
      }
    },
    clickTab(key){
      var self = this;
      self.activeTab = key
      self.saleByOwner = '';
      //  if(key == "mental_or_detox_facility"){
      //     self.saleByOwner = self.saleByOwnerLastValue;
      //   }
      //   if(key == "free_or_public_shelter"){
      //     self.saleByOwner = self.assignYourContractValue;
      //   }
      //   if(key == "half_way_or_shared"){
      //     self.saleByOwner=  self.listYourNoteValue;
      //   }
      self.$refs.saleByOwnerForm.reset();
      self.priceError = '';
    },
    cancelTab(){
      var self = this;
      self.saleByOwner = '';
      //  if(key == "mental_or_detox_facility"){
      //     self.saleByOwner = self.saleByOwnerLastValue;
      //   }
      //   if(key == "free_or_public_shelter"){
      //     self.saleByOwner = self.assignYourContractValue;
      //   }
      //   if(key == "half_way_or_shared"){
      //     self.saleByOwner=  self.listYourNoteValue;
      //   }
      self.$refs.saleByOwnerForm.reset();
      self.priceError = '';
    },
    compareObjects(object1, object2, key) {
      const obj1 = object1[key];
      const obj2 = object2[key];
      if (obj1 > obj2) {
        return -1;
      }
      if (obj1 < obj2) {
        return 1;
      }
      return 0;
    },
    sortingArray(array){
      var self = this;
      array.sort((book1, book2) => {
        return self.compareObjects(book1, book2, "dateInSec");
      });
    },
    setCommaSeprateNumber(price){
      return getCommaSeperatedNumber(price);
    }
  },
  computed: {
    ...mapGetters({"subscriptionPriceArray":"admin/subscriptionPrice"})
  }
};
</script>

<style>
.spinner {
  z-index: 999;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
.mt-30 {
  margin-top: 30px;
}
</style>